@font-face {
  font-family: pixel;

  src: url("./assets/fonts/SourceCodePro.ttf");
}

body {
  font-family: pixel;
  background-color: #f7931a;
  background-image: url("./assets/bg.jpg");
  background-position: center top;
  background-size: 100% 100%;
  /* color: aliceblue;
  font-family: "Shantell Sans", serif;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  padding: 0;
  margin: 0;
  height: 100vh;
}
.disable-link {
  pointer-events: none;
}
.text-arcade {
  font-family: pixel;
  color: rgba(15, 15, 15, 0.678);
}
.text-pixel {
  font-family: pixel;
  color: rgba(15, 15, 15, 0.678);
}
.BR-5 {
  border-radius: 5px;
}
.shadow {
  -webkit-box-shadow: 0px 9px 21px -4px rgba(0, 0, 0, 0.73);
  box-shadow: 0px 9px 21px -4px rgba(0, 0, 0, 0.73);
}
.btn-cus {
  border: 0px solid white !important;
}
.con {
  width: 100%;
  min-height: 500px;
  max-height: calc(100vh - 150px) !important;
  overflow: auto;
  max-width: 1780px;
  /* border-radius: 0.375rem;
  border-style: solid;
  border-width: 0.125rem;
  box-sizing: border-box;
  border-color: #f7931a; */
  /* opacity: 0.8; */
}
.c-pointer {
  cursor: pointer !important;
}
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1500; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}
.view {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1043; /* Sit on top */
  left: 0;
  top: 0;
  background-color: #3c2130; /* Black fallback color */

  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.view-content {
  position: relative;

  width: 80%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}
.view-img {
  width: 200px;
  border: 6px solid #47414d;
}
.view-style {
  border: 6px solid #47414d;
  -webkit-box-shadow: 3px 3px 0px 3px rgba(0, 0, 0, 1);
  -moz-box-shadow: 3px 3px 0px 3px rgba(0, 0, 0, 1);
  box-shadow: 3px 3px 0px 3px rgba(0, 0, 0, 1);
}

.view-sat {
  width: 145px;
  height: 45px;

  background-color: #f74900;
  background-position: 50% 50%;
}
.view-close {
  color: red !important;
  font-size: xx-large;
}
.not-underline {
  text-decoration: none;
}
.view-btn {
  background-color: #1d9bf0;
}
.w-300px {
  max-width: 350px !important;
}
.bg-ordi {
  background-color: #f7931a !important;
}
.bg-ordi2 {
  background-color: #ff4900;
}
.spinner-grow {
  width: 4rem !important;
  height: 4rem !important;
}
.footer {
  /* background-image: url("./images/footer.png"); */
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
}
.work {
  height: 100vh !important;
}
.border-cus {
  /* border: 1px solid rgba(255, 255, 255, 0.24); */
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 0.125rem;
  box-sizing: border-box;
  border-color: #f7931a;
}
.w-border-cus {
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 10px;
  height: 68px;
}
.op-1 {
  opacity: 1 !important;
}
hr {
  opacity: 1 !important;
}
.img-pix {
  image-rendering: pixelated !important;
}
.text-bit {
  color: #f7931a !important;
}
.fs-8 {
  font-size: 0.8rem !important;
}
.fs-7 {
  font-size: 0.9rem !important;
}
.fs-9 {
  font-size: 0.65rem !important;
}
.w-cus {
  max-width: 1240px;
  /* max-width: 100%; */
}
.block78-shadow {
  filter: drop-shadow(-1px -1px 3px #b722358e)
    drop-shadow(1px 1px 3px #b722358e);
}
.w-1-17rem {
  width: 1.17rem;
}
.aspect-square {
  aspect-ratio: 1/1;
}
.relative {
  position: relative;
}
.vintage-shadow {
  filter: drop-shadow(-1px -1px 3px #fbbd808e)
    drop-shadow(1px 1px 3px #fbbd808e);
}
.pizza-shadow {
  filter: drop-shadow(-1px -1px 3px #fcd2588e)
    drop-shadow(1px 1px 3px #fcd2588e);
}
.h-fit {
  height: fit-content !important;
}
.z-10 {
  z-index: 10 !important;
}
.w-choose {
  cursor: pointer;
}
.w-choose:hover {
  cursor: pointer;
  background-color: #47414d8a;
}
.border-err {
  border: 1px solid red;
}

/* CSS */
.ord-btn {
  backface-visibility: hidden;
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 0.125rem;
  box-sizing: border-box;
  border-color: #f7931a;
  color: #f7931a;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, Helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.3;
  padding: 0.875rem 1.125rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ord-btn:not(:disabled):hover {
  transform: scale(1.05);
}

.ord-btn:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.ord-btn:focus {
  outline: 0 solid transparent;
}

.ord-btn:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.ord-btn:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.ord-btn:focus:not(:focus-visible):before {
  border-width: 0;
}

.ord-btn:not(:disabled):active {
  transform: translateY(0.125rem);
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.image {
  max-width: 400px;
}
